import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { H1, H2, Paragraph } from 'components/common/Styles';
import { ICreator } from 'apiFolder/directus-main';

import { ContentArticle820 } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import ImageContainerComponent from 'components/common/image/ImageContainer';

interface ITeam {
	pageContext: {
		dataEditorialTeamContentCreators: ICreator[];
	};
}

const EditorialTeam: React.FC<ITeam> = ({ pageContext: { dataEditorialTeamContentCreators } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo
				title='Content Editorial Team'
				description='Find out why we created BookPhysio.com, an on-demand physiotherapy booking site that connects patients with experienced physiotherapists.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<TopPadding />
						<Paragraph />
						<H1>Our Editorial Leadership Team</H1>
						<Section>
							<Paragraph>
								Our editorial team is responsible for ensuring the content on BookPhysio.com meets the
								standards we set in our
								<HoveredInline>
									<Link to='/editorial-policy'>Editorial Policy</Link>.
								</HoveredInline>
							</Paragraph>
							<Paragraph>
								The team also provides the strategic direction of our content creation and works closely
								with a panel of expert contributors and medical reviewers to ensure the content on
								BookPhysio.com is up to date and accurate
							</Paragraph>
							<Paragraph>
								“For those of us working at BookPhysio.com, the topic of healthcare is something we’re
								all passionate about - which is what motivates us to build upon our library of health
								information, to help you get back to feeling your best - no matter what you're dealing
								with.”<Span>- Leon Mao, Senior Content Producer</Span>
							</Paragraph>
							<Line />
						</Section>
						<Section>
							<ImgBlock>
								<StaticImage
									src='../images/editorial-team/leon.jpg'
									alt='Dr Leon Mao'
									width={200}
									height={200}
								/>
							</ImgBlock>
							<Paragraph>
								<Link to='/author/leon-mao'>
									<Span>Leon Mao, Senior Content Producer</Span>
								</Link>
								<SpanGrey>
									Bachelor of Science with Honours, Doctorate of Physiotherapy (University of
									Melbourne)
								</SpanGrey>
							</Paragraph>
							<Paragraph>
								Leon Mao is responsible for producing and editing content on BookPhysio.com and guiding
								the strategic direction of the content produced.
							</Paragraph>
							<Paragraph>
								In his role in the leadership team, Leon is also responsible for ensuring our content
								meets the standards we set in our overall editorial policy. Leon works closely with our
								panel of expert contributors and medical reviewers to ensure that what we publish is up
								to date and accurate.
							</Paragraph>
						</Section>
						<Section>
							<ImgBlock>
								<StaticImage
									src='../images/editorial-team/gina-arena.jpg'
									alt='Dr Gina Arena'
									width={200}
									height={200}
								/>
							</ImgBlock>
							<Paragraph>
								<Link to='/medical-reviewer/gina-arena'>
									<Span>Dr Gina Arena — Senior Medical Reviewer</Span>
								</Link>
								<SpanGrey>
									{`Research Fellow & Lecturer at the University of Western Australia`}
								</SpanGrey>
							</Paragraph>
							<Paragraph>
								Dr Gina Arena is the senior medical reviewer for content published within
								BookPhysio.com’s patient resources. All articles are reviewed by Dr Arena to ensure
								medical accuracy, with appropriate primary literature citations to bolster the evidence
								behind the content.
							</Paragraph>
						</Section>
						<Section>
							<H2>Editorial Contributors and Experts</H2>
							<Paragraph>
								We aim to construct content that is balanced and objective. To this end, we maintain a
								healthy relationship between many different Australian-based health practitioners - who
								often contribute and provide perspective from their area of medical expertise.
							</Paragraph>
							<Row>
								{dataEditorialTeamContentCreators.map((creator) => (
									<Cell key={creator.slug}>
										<ImageBox>
											<ImageContainerComponent
												src={`${creator.profilePicture}?key=medium`}
												alt={creator.name}
												thumb={`${creator.profilePicture}?key=small`}
												isRound
											/>
										</ImageBox>
										<Link to={creator.slug}>
											<Span>{creator.name}</Span>
										</Link>
										<SpanGrey>Senior Contributor</SpanGrey>
										<SpanGrey>{`Qualified ${creator.medicalTitle}`}</SpanGrey>
									</Cell>
								))}
							</Row>
						</Section>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const Section = styled.section`
	& a {
		color: #333e48;
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.purp};
			text-decoration: none;
		}
	}
`;
const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Cell = styled.div`
	flex: 0 0 33.3%;
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-bottom: 30px;
	font-weight: 300;

	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 375px) {
		flex: 0 0 100%;
	}
`;
const ImageBox = styled.div`
	& img {
		aspect-ratio: 1 / 1;
		border-radius: 50%;
		max-width: 160px;
		width: 100%;
		box-shadow: inset 0px 0px 15px -5px #0f0e15a1;
	}
`;
const Span = styled.span`
	display: block;
	font-weight: 600;
	margin-top: 20px;
`;
const SpanGrey = styled(Span)`
	font-size: 16px;
	font-weight: 300;
	color: #7c7c7c;
	margin-top: 0px;
`;
const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: #000000;
	margin-top: 40px;
	margin-bottom: 40px;
`;

const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0 5px;
	&:first-child {
		margin: 0 5px;
	}
`;
const ImgBlock = styled.div`
	width: 100%;
	height: 200px;
	& > div {
		border-radius: 3px;
		overflow: hidden;
	}
`;

export default EditorialTeam;
